import { motion } from 'framer-motion';
import { FaMapMarkerAlt, FaCalendarAlt, FaClock } from 'react-icons/fa';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const ConferenceCard = ({ title, date, location, description, status }) => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="bg-white rounded-xl shadow-lg overflow-hidden"
    >
        <div className="bg-tropaz-900 px-6 py-4">
            <h3 className="text-xl font-bold text-white">{title}</h3>
            {status && (
                <span className="inline-block mt-2 px-3 py-1 bg-tropaz-700 text-white text-sm rounded-full">
                    {status}
                </span>
            )}
        </div>
        <div className="p-6">
            <div className="space-y-4">
                <div className="flex items-center text-gray-600">
                    <FaCalendarAlt className="w-5 h-5 text-tropaz-600 mr-3" />
                    <span>{date}</span>
                </div>
                <div className="flex items-center text-gray-600">
                    <FaMapMarkerAlt className="w-5 h-5 text-tropaz-600 mr-3" />
                    <span>{location}</span>
                </div>
                {description && (
                    <p className="text-gray-600 mt-4">
                        {description}
                    </p>
                )}
            </div>
        </div>
    </motion.div>
);

export default function Conferences() {
    const conferences = [
        {
            title: "District 8 Conference",
            date: "January 28, 2024",
            location: "The Regent, Riverview, FL",
            description: "Join us for the District 8 Conference where members showcase their skills and compete in various business-related events.",
            status: "Upcoming"
        },
        {
            title: "Florida State Leadership Conference",
            date: "March 28 - 31, 2024",
            location: "Hilton Orlando, Orlando",
            description: "The state-level conference brings together top performers from across Florida to compete and network with fellow FBLA members.",
            status: "Registration Open"
        },
        {
            title: "National Leadership Conference",
            date: "June 29 - July 2, 2024",
            location: "Anaheim, CA",
            description: "The pinnacle of FBLA competition, where state winners compete at the national level and attend leadership workshops.",
            status: "Coming Soon"
        }
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen bg-gray-50"
        >
            <Navbar />

            <main className="pt-24 pb-16">
                <div className="max-w-7xl mx-auto px-4">
                    {/* Hero Section */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-center mb-16"
                    >
                        <h1 className="text-5xl md:text-6xl font-bold text-tropaz-900 mb-6">
                            FBLA Conferences
                        </h1>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Experience leadership, competition, and networking at our annual conferences
                        </p>
                    </motion.div>

                    {/* Timeline */}
                    <div className="relative mb-20">
                        <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-tropaz-200" />
                        <div className="space-y-12">
                            {conferences.map((conference, index) => (
                                <div key={index} className={`flex flex-col md:flex-row ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'} gap-8`}>
                                    <div className="w-full md:w-1/2">
                                        <ConferenceCard {...conference} />
                                    </div>
                                    <div className="hidden md:block w-1/2" />
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* CTA Section */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="bg-gradient-to-r from-tropaz-900 to-tropaz-800 text-white rounded-2xl p-8 text-center"
                    >
                        <h2 className="text-3xl font-bold mb-4">Ready to Compete?</h2>
                        <p className="text-lg opacity-90 mb-8">
                            Register for upcoming conferences and start your journey to success
                        </p>
                        <motion.a
                            href="/contact"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="inline-block bg-white text-tropaz-900 px-8 py-3 rounded-lg font-semibold hover:bg-tropaz-50 transition-colors duration-300"
                        >
                            Register Now
                        </motion.a>
                    </motion.div>
                </div>
            </main>

            <Footer />
        </motion.div>
    );
}
