import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Officers from './pages/Officers';
import Members from './pages/Members';
import Contacts from './pages/Contact';
import Conferences from './pages/Conferences';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <div className='font-poppins min-h-screen bg-gradient-to-br from-tropaz-50 to-tropaz-100'>
      <BrowserRouter>
        <AnimatePresence mode='wait'>
          <Routes>
            <Route index element={<Home/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/officers" element={<Officers/>}/>
            <Route path="/members" element={<Members/>}/>
            <Route path="/contact" element={<Contacts/>}/>
            <Route path="/conferences" element={<Conferences/>}/>
          </Routes>
        </AnimatePresence>
      </BrowserRouter>
    </div>
  );
}

export default App;
