import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiMenu, FiX } from 'react-icons/fi';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [activeLink, setActiveLink] = useState('/');

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 20;
            setScrolled(isScrolled);
        };

        // Set active link based on current path
        setActiveLink(window.location.pathname);

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const toggleMenu = () => setIsOpen(!isOpen);

    const navLinks = [
        { href: '/home', text: 'Home' },
        { href: '/about', text: 'About' },
        { href: '/officers', text: 'Officers' },
        { href: '/members', text: 'Members' },
        { href: '/conferences', text: 'Conferences' },
        { href: '/contact', text: 'Contact' }
    ];

    return (
        <motion.nav 
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            className={`fixed w-full top-0 z-50 transition-all duration-300 ${
                scrolled ? 'bg-tropaz-900 shadow-lg' : 'bg-tropaz-900'
            }`}
        >
            <div className="container mx-auto px-4 py-3">
                <div className="flex justify-between items-center">
                    <motion.a 
                        href="/home"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="relative z-10 flex items-center space-x-2"
                    >
                        <img src="images/logo.png" className="w-10 h-10 rounded-full" alt="Logo" />
                        <span className="text-white font-semibold text-lg sm:text-xl">Wharton FBLA</span>
                    </motion.a>

                    {/* Desktop Navigation */}
                    <div className="hidden md:flex items-center space-x-1">
                        {navLinks.map((link, index) => (
                            <motion.a
                                key={link.href}
                                href={link.href}
                                className={`px-4 py-2 rounded-lg text-sm font-medium transition-all relative group
                                    ${activeLink === link.href 
                                        ? 'text-white bg-white/10' 
                                        : 'text-gray-300 hover:text-white hover:bg-white/5'}`}
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: index * 0.1 }}
                            >
                                {link.text}
                                <motion.span 
                                    className={`absolute bottom-0 left-0 w-full h-0.5 bg-tropaz-400
                                        ${activeLink === link.href ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}
                                    layoutId="underline"
                                />
                            </motion.a>
                        ))}
                    </div>

                    {/* Mobile Menu Button */}
                    <motion.button
                        whileTap={{ scale: 0.95 }}
                        className="md:hidden text-white p-2 hover:bg-white/10 rounded-lg transition-colors"
                        onClick={toggleMenu}
                    >
                        {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
                    </motion.button>
                </div>

                {/* Mobile Navigation */}
                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                            className="md:hidden overflow-hidden bg-tropaz-800/95 backdrop-blur-sm mt-4 rounded-xl border border-white/10"
                        >
                            <div className="flex flex-col space-y-1 p-3">
                                {navLinks.map((link, index) => (
                                    <motion.a
                                        key={link.href}
                                        href={link.href}
                                        className={`text-sm font-medium px-4 py-3 rounded-lg transition-all
                                            ${activeLink === link.href 
                                                ? 'text-white bg-white/10' 
                                                : 'text-gray-300 hover:text-white hover:bg-white/5'}`}
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: index * 0.1 }}
                                        onClick={toggleMenu}
                                    >
                                        {link.text}
                                    </motion.a>
                                ))}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </motion.nav>
    );
}
