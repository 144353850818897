import { motion } from 'framer-motion';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const OfficerCard = ({ name, position, image, team, description }) => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        whileHover={{ y: -10 }}
        className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 p-6"
    >
        <div className="relative group flex justify-center mb-4">
            <div className="w-48 h-48 rounded-full overflow-hidden ring-4 ring-tropaz-100">
                <img 
                    src={image} 
                    className="w-full h-full object-cover object-center transition-transform duration-300 group-hover:scale-105" 
                    alt={name} 
                />
            </div>
        </div>
        <div className="text-center">
            <h3 className="text-xl font-bold text-tropaz-900">{name}</h3>
            <p className="text-tropaz-600 font-medium mb-3">{position}</p>
            <p className="text-gray-600 text-sm">{description}</p>
        </div>
    </motion.div>
);

const TeamSection = ({ title, officers }) => (
    <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="w-full max-w-7xl mx-auto mb-16"
    >
        <div className="relative mb-12">
            <h2 className="text-4xl font-bold text-tropaz-900 text-center">
                {title}
            </h2>
            <div className="absolute w-24 h-1 bg-tropaz-600 bottom-0 left-1/2 transform -translate-x-1/2 mt-4" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
            {officers.map((officer, index) => (
                <OfficerCard key={officer.name} {...officer} />
            ))}
        </div>
    </motion.div>
);

export default function Officers() {
    const teamAOfficers = [
        {
            name: "Vy Doan",
            position: "President",
            image: "images/vy.png",
            description: "Leading FBLA with dedication and innovation, focusing on member growth and chapter success."
        },
        {
            name: "Satya Somalanka",
            position: "Vice President",
            image: "images/satya.png",
            description: "Supporting chapter initiatives and coordinating events to enhance member experience."
        },
        {
            name: "Khushbu Patel",
            position: "Secretary",
            image: "images/khushbu.png",
            description: "Maintaining chapter records and ensuring smooth communication between members."
        },
        {
            name: "Fernando Soto-Briceno",
            position: "Treasurer",
            image: "images/fernando.jpg",
            description: "Managing chapter finances and budgeting for events and activities."
        },
        {
            name: "Himani Mehra",
            position: "Reporter",
            image: "images/himani.png",
            description: "Documenting chapter activities and maintaining social media presence."
        },
        {
            name: "Luke Dostal",
            position: "Historian",
            image: "images/luke.png",
            description: "Preserving chapter history and creating memorable content for members."
        }
    ];

    const teamBOfficers = [
        {
            name: "Aarna Patel",
            position: "President",
            image: "images/aarna.png",
            description: "Guiding the chapter with strategic vision and fostering leadership development."
        },
        {
            name: "Chavi Lakhotia",
            position: "Vice President",
            image: "images/chavi.png",
            description: "Implementing chapter programs and mentoring new members."
        },
        {
            name: "Eric Fernandes",
            position: "Secretary",
            image: "images/eric.png",
            description: "Organizing chapter documentation and facilitating effective communication."
        },
        {
            name: "Ananda Ravinder",
            position: "Treasurer",
            image: "images/ananda.png",
            description: "Overseeing financial planning and fundraising initiatives."
        },
        {
            name: "Charlize Buie",
            position: "Reporter",
            image: "images/charlize.png",
            description: "Sharing chapter success stories and managing public relations."
        },
        {
            name: "Aayush Iyer",
            position: "Historian",
            image: "images/aayush.png",
            description: "Capturing chapter moments and maintaining the historical record."
        }
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen bg-gray-50"
        >
            <Navbar />

            <main className="pt-24 pb-16">
                <div className="max-w-7xl mx-auto px-4">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-center mb-16"
                    >
                        <h1 className="text-5xl md:text-6xl font-bold text-tropaz-900 mb-6">
                            Meet Our Officers
                        </h1>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Dedicated leaders working together to create opportunities and foster success in our FBLA chapter
                        </p>
                    </motion.div>

                    <TeamSection title="Team A" officers={teamAOfficers} />
                    <TeamSection title="Team B" officers={teamBOfficers} />
                </div>
            </main>

            <Footer />
        </motion.div>
    );
}
