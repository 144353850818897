import { motion } from 'framer-motion';
import { FaInstagram, FaComments, FaMapMarkerAlt } from 'react-icons/fa';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const ContactCard = ({ icon: Icon, title, description, link, linkText, delay }) => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay }}
        className="w-full md:w-1/2 lg:w-1/2 p-4"
    >
        <div className="h-full bg-white rounded-2xl shadow-lg p-8 border-l-4 border-tropaz-500 hover:shadow-xl transition-shadow duration-300">
            <div className="flex flex-col items-center text-center">
                <div className="w-16 h-16 bg-tropaz-50 rounded-full flex items-center justify-center mb-4">
                    <Icon className="w-8 h-8 text-tropaz-600" />
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">{title}</h3>
                <p className="text-gray-600 mb-4">{description}</p>
                {link && (
                    <motion.a
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center justify-center px-6 py-3 rounded-lg bg-tropaz-600 text-white font-medium hover:bg-tropaz-700 transition-colors duration-300"
                    >
                        {linkText}
                    </motion.a>
                )}
            </div>
        </div>
    </motion.div>
);

export default function Contact() {
    const contactInfo = [
        {
            icon: FaInstagram,
            title: "Instagram",
            description: "Follow us for updates, event photos, and announcements",
            link: "https://www.instagram.com/whartonhs.fbla",
            linkText: "Follow @whartonhs.fbla"
        },
        {
            icon: FaComments,
            title: "Band Chat",
            description: "Join our community chat for real-time updates and discussions",
            link: "https://band.us/n/a9a9A3O3p7Jci",
            linkText: "Join Band Chat"
        },
        {
            icon: FaMapMarkerAlt,
            title: "Visit Us",
            description: "Room 405 - Wharton High School",
            link: "https://maps.google.com/?q=Wharton+High+School",
            linkText: "Get Directions"
        }
    ];

    return (
        <div className="min-h-screen bg-gray-50">
            <Navbar />

            <main className="pt-24 pb-16">
                <div className="max-w-7xl mx-auto px-4">
                    {/* Hero Section */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-center mb-16"
                    >
                        <h1 className="text-5xl md:text-6xl font-bold text-tropaz-900 mb-6">
                            Get in Touch
                        </h1>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Connect with Wharton FBLA through various channels and stay updated with our latest activities
                        </p>
                    </motion.div>

                    {/* Contact Cards */}
                    <div className="flex flex-wrap -mx-4 mb-16">
                        {contactInfo.map((info, index) => (
                            <ContactCard key={info.title} {...info} delay={index * 0.1} />
                        ))}
                    </div>

                    {/* FAQ Section */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="bg-white rounded-2xl shadow-lg p-8 mb-16"
                    >
                        <h2 className="text-3xl font-bold text-tropaz-900 mb-8 text-center">
                            Frequently Asked Questions
                        </h2>
                        <div className="grid md:grid-cols-2 gap-8">
                            <div>
                                <h3 className="font-bold text-lg mb-2">When are the meetings?</h3>
                                <p className="text-gray-600">We meet every club day [Monday] in Room 405 after school. Check our Band Chat for specific dates and times.</p>
                            </div>
                            <div>
                                <h3 className="font-bold text-lg mb-2">How do I join FBLA?</h3>
                                <p className="text-gray-600">During the registration period, you can join FBLA by filling out the membership form.</p>
                            </div>
                            <div>
                                <h3 className="font-bold text-lg mb-2">What are the membership dues?</h3>
                                <p className="text-gray-600">Annual membership dues are $45. This covers local conference fees and membership benefits.</p>
                            </div>
                            <div>
                                <h3 className="font-bold text-lg mb-2">How can I compete?</h3>
                                <p className="text-gray-600">Members can participate in various competitions at our conferences. Talk to our officers about competition opportunities!</p>
                            </div>
                        </div>
                    </motion.div>

                    {/* CTA Section */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="bg-gradient-to-r from-tropaz-900 to-tropaz-800 rounded-2xl p-8 text-center text-white"
                    >
                        <h2 className="text-3xl font-bold mb-4">Ready to Join?</h2>
                        <p className="text-lg opacity-90 mb-8">
                            Take the first step towards becoming a future business leader
                        </p>
                        <motion.a
                            href="/about"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="inline-block bg-white text-tropaz-900 px-8 py-3 rounded-lg font-semibold hover:bg-tropaz-50 transition-colors duration-300"
                        >
                            Learn More
                        </motion.a>
                    </motion.div>
                </div>
            </main>

            <Footer />
        </div>
    );
}
