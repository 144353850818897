import { motion } from 'framer-motion';
import { FaUsers, FaTrophy, FaGlobe, FaHandshake } from 'react-icons/fa';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const StatCard = ({ icon: Icon, number, text }) => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="bg-white rounded-xl shadow-lg p-6 flex flex-col items-center"
    >
        <div className="bg-tropaz-50 p-4 rounded-full mb-4">
            <Icon className="text-tropaz-600 text-3xl" />
        </div>
        <h3 className="text-4xl font-bold text-tropaz-900 mb-2">{number}</h3>
        <p className="text-gray-600 text-center font-medium">{text}</p>
    </motion.div>
);

export default function About() {
    const stats = [
        { icon: FaUsers, number: "45+", text: "Active Members" },
        { icon: FaTrophy, number: "18", text: "Awards Won" },
        { icon: FaGlobe, number: "230K+", text: "Global Network" },
        { icon: FaHandshake, number: "50+", text: "Partner Companies" }
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen bg-gray-50"
        >
            <Navbar />

            <main className="pt-24 pb-16">
                <div className="max-w-7xl mx-auto px-4">
                    {/* Hero Section */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-center mb-16"
                    >
                        <h1 className="text-5xl md:text-6xl font-bold text-tropaz-900 mb-6">
                            About FBLA
                        </h1>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Empowering students to become community-minded business leaders in a global society
                        </p>
                    </motion.div>

                    {/* Main Content */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-20">
                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            className="space-y-6"
                        >
                            <div className="bg-white rounded-xl shadow-lg p-8">
                                <h2 className="text-2xl font-bold text-tropaz-900 mb-4">Mission</h2>
                                <p className="text-gray-600 leading-relaxed">
                                    Future Business Leaders of America (FBLA) is a student organization that prepares students 
                                    for careers in business and leadership through academic competitions, leadership development, 
                                    and educational programs. It is the largest career student business organization in the world.
                                </p>
                            </div>

                            <div className="bg-white rounded-xl shadow-lg p-8">
                                <h2 className="text-2xl font-bold text-tropaz-900 mb-4">What We Offer</h2>
                                <p className="text-gray-600 leading-relaxed">
                                    FBLA offers various opportunities for students to engage in business-related activities, 
                                    including competitive events at regional, state, and national levels. Students can showcase 
                                    their skills in areas like public speaking, business management, finance, and entrepreneurship.
                                </p>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, x: 20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            className="space-y-6"
                        >
                            <div className="bg-white rounded-xl shadow-lg p-8">
                                <h2 className="text-2xl font-bold text-tropaz-900 mb-4">Legacy</h2>
                                <p className="text-gray-600 leading-relaxed">
                                    Since its founding in 1940 by Dr. Hamden L. Forkner, FBLA has grown into a premier business 
                                    student organization. It has played a crucial role in shaping the lives of millions of students 
                                    by equipping them with the skills necessary to succeed in the business world.
                                </p>
                            </div>

                            <div className="bg-white rounded-xl shadow-lg p-8">
                                <h2 className="text-2xl font-bold text-tropaz-900 mb-4">Community Impact</h2>
                                <p className="text-gray-600 leading-relaxed">
                                    FBLA emphasizes community service and networking, helping students build connections and gain 
                                    real-world experience. Many FBLA alumni have gone on to become successful entrepreneurs, 
                                    executives, and leaders in various industries.
                                </p>
                            </div>
                        </motion.div>
                    </div>

                    {/* Stats Section */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="text-center mb-12"
                    >
                        <h2 className="text-3xl font-bold text-tropaz-900 mb-2">By The Numbers</h2>
                        <p className="text-xl text-gray-600 mb-12">2023-2024 School Year</p>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {stats.map((stat, index) => (
                                <StatCard key={index} {...stat} />
                            ))}
                        </div>
                    </motion.div>

                    {/* CTA Section */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="bg-tropaz-900 text-white rounded-2xl p-8 text-center"
                    >
                        <h2 className="text-3xl font-bold mb-4">Join Wharton FBLA</h2>
                        <p className="text-lg opacity-90 mb-8">
                            Be part of something bigger. Join us in shaping the future of business leadership.
                        </p>
                        <motion.a
                            href="/contact"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="inline-block bg-white text-tropaz-900 px-8 py-3 rounded-lg font-semibold hover:bg-tropaz-50 transition-colors duration-300"
                        >
                            Get Started
                        </motion.a>
                    </motion.div>
                </div>
            </main>

            <Footer />
        </motion.div>
    );
}
