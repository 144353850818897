import { motion } from 'framer-motion';
import { FaInstagram, FaEnvelope, FaMapMarkerAlt, FaChevronRight } from 'react-icons/fa';

export default function Footer() {
    const quickLinks = [
        { name: 'About', href: '/about' },
        { name: 'Officers', href: '/officers' },
        { name: 'Members', href: '/members' },
        { name: 'Conferences', href: '/conferences' },
        { name: 'Contact', href: '/contact' }
    ];

    const resources = [
        { name: 'FBLA National', href: 'https://www.fbla-pbl.org/' },
        { name: 'Florida FBLA', href: 'https://www.floridafbla-pbl.com/' },
        { name: 'Competition Guidelines', href: 'https://www.fbla-pbl.org/divisions/fbla/fbla-competitive-events/' },
        { name: 'Band Chat', href: 'https://band.us/n/a9a9A3O3p7Jci' }
    ];

    return (
        <footer className="bg-tropaz-900 text-white">
            <div className="max-w-7xl mx-auto px-4 pt-12 sm:pt-16 pb-8">
                {/* Main Footer Content */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
                    {/* Brand Section */}
                    <div className="space-y-4">
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            className="flex items-center space-x-3"
                        >
                            <img src="/images/logo.png" alt="Wharton FBLA Logo" className="w-10 h-10 sm:w-12 sm:h-12 rounded-full" />
                            <span className="text-xl sm:text-2xl font-bold">Wharton FBLA</span>
                        </motion.div>
                        <p className="text-sm sm:text-base text-gray-300">
                            Empowering and preparing students to become community-minded business leaders in a global society.
                        </p>
                        <div className="flex space-x-4">
                            <motion.a
                                whileHover={{ scale: 1.1 }}
                                href="https://www.instagram.com/whartonhs.fbla/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-white hover:text-orange-300 transition-colors"
                            >
                                <FaInstagram size={24} />
                            </motion.a>
                            <motion.a
                                whileHover={{ scale: 1.1 }}
                                href="https://maps.google.com/?q=Wharton+High+School"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-white hover:text-orange-300 transition-colors"
                            >
                                <FaMapMarkerAlt size={24} />
                            </motion.a>
                        </div>
                    </div>

                    {/* Quick Links */}
                    <div className="sm:pl-4">
                        <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
                        <ul className="space-y-2 text-sm sm:text-base">
                            {quickLinks.map((link) => (
                                <motion.li
                                    key={link.name}
                                    whileHover={{ x: 5 }}
                                    className="flex items-center"
                                >
                                    <FaChevronRight className="w-3 h-3 text-orange-300 mr-2" />
                                    <a
                                        href={link.href}
                                        className="text-gray-300 hover:text-white transition-colors"
                                    >
                                        {link.name}
                                    </a>
                                </motion.li>
                            ))}
                        </ul>
                    </div>

                    {/* Resources */}
                    <div className="sm:pl-4">
                        <h3 className="text-lg font-semibold mb-4">Resources</h3>
                        <ul className="space-y-2 text-sm sm:text-base">
                            {resources.map((resource) => (
                                <motion.li
                                    key={resource.name}
                                    whileHover={{ x: 5 }}
                                    className="flex items-center"
                                >
                                    <FaChevronRight className="w-3 h-3 text-orange-300 mr-2" />
                                    <a
                                        href={resource.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-gray-300 hover:text-white transition-colors"
                                    >
                                        {resource.name}
                                    </a>
                                </motion.li>
                            ))}
                        </ul>
                    </div>

                    {/* Meeting Info */}
                    <div>
                        <h3 className="text-lg font-semibold mb-4">Meeting Information</h3>
                        <ul className="space-y-3 text-gray-300">
                            <li>
                                <strong className="text-white">Location:</strong>
                                <p>Room 405</p>
                            </li>
                            <li>
                                <strong className="text-white">Schedule:</strong>
                                <p>Every club day</p>
                                <p>5th Period</p>
                            </li>
                            <li>
                                <strong className="text-white">Advisor:</strong>
                                <p>Ms. Chisholm</p>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Bottom Bar */}
                <div className="pt-8 border-t border-gray-800">
                    <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                        <p className="text-sm text-gray-400">
                            &copy; {new Date().getFullYear()} Wharton FBLA. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
