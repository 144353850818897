import { motion } from 'framer-motion';
import { FaArrowRight, FaTrophy, FaUsers, FaLightbulb } from 'react-icons/fa';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Home() {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen bg-gradient-to-br from-tropaz-700 to-tropaz-900 relative overflow-hidden"
        >
            {/* Animated Background */}
            <div className="absolute inset-0 overflow-hidden pointer-events-none">
                <div className="absolute -inset-[10px] opacity-50">
                    {[...Array(6)].map((_, i) => (
                        <div
                            key={i}
                            className="absolute animate-blob rounded-full mix-blend-multiply filter blur-xl"
                            style={{
                                backgroundColor: i % 2 === 0 ? '#1e3a8a' : '#1e40af',
                                width: `${Math.random() * 30 + 20}%`,
                                height: `${Math.random() * 30 + 20}%`,
                                left: `${Math.random() * 80}%`,
                                top: `${Math.random() * 80}%`,
                                animation: `blob ${Math.random() * 10 + 20}s infinite ease-in-out`,
                                animationDelay: `${i * -5}s`
                            }}
                        />
                    ))}
                </div>
            </div>
            <div className="relative z-10">
                <Navbar />
                
                {/* Hero Section */}
                <div className="w-full min-h-screen flex flex-col items-center justify-center p-4 relative overflow-hidden">
                    <motion.img 
                        initial={{ scale: 0.5, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className="w-32 sm:w-48 md:w-64 lg:w-80 hover:scale-105 transition-transform duration-300" 
                        src="images/FBLA.png" 
                        alt="FBLA Logo" 
                    />
                    
                    <motion.h1 
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                        className="text-3xl sm:text-4xl md:text-6xl lg:text-7xl text-white font-bold uppercase text-center mt-4 tracking-wider px-4"
                    >
                        Wharton FBLA
                    </motion.h1>

                    <motion.p
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.4, duration: 0.5 }}
                        className="text-lg md:text-xl text-white text-center mt-6 max-w-2xl px-6"
                    >
                        Empowering future business leaders through education, innovation, and leadership
                    </motion.p>

                    <div className="flex flex-col sm:flex-row items-center justify-center w-full px-6 sm:w-auto mt-12 space-y-4 sm:space-y-0 sm:space-x-6">
                        <motion.a
                            initial={{ x: -50, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: 0.6, duration: 0.5 }}
                            className="group w-48 h-14 rounded-lg text-tropaz-700 font-semibold flex items-center justify-center bg-white hover:bg-tropaz-800 hover:text-white transform hover:scale-105 transition-all duration-300"
                            href="/about"
                        >
                            About Us
                            <FaArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all" />
                        </motion.a>
                        <motion.a
                            initial={{ x: 50, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: 0.7, duration: 0.5 }}
                            className="group w-48 h-14 rounded-lg text-white font-semibold flex items-center justify-center border-2 border-white hover:bg-white hover:text-tropaz-700 transform hover:scale-105 transition-all duration-300"
                            href="/officers"
                        >
                            Meet Our Team
                            <FaArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all" />
                        </motion.a>
                    </div>
                </div>

                {/* Features Section */}
                <div className="w-full bg-white py-16 sm:py-20 px-4">
                    <div className="max-w-7xl mx-auto">
                        <h2 className="text-2xl sm:text-3xl md:text-5xl font-bold text-center text-tropaz-900 mb-12 sm:mb-16" data-aos="fade-up">
                            Why Join FBLA?
                        </h2>
                        
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8">
                            <div className="bg-tropaz-50 p-8 rounded-xl" data-aos="fade-up" data-aos-delay="100">
                                <FaTrophy className="w-12 h-12 text-tropaz-700 mb-4" />
                                <h3 className="text-xl font-bold mb-4">Competitions</h3>
                                <p className="text-gray-600">Participate in regional and national competitions to showcase your business skills.</p>
                            </div>
                            
                            <div className="bg-tropaz-50 p-8 rounded-xl" data-aos="fade-up" data-aos-delay="200">
                                <FaUsers className="w-12 h-12 text-tropaz-700 mb-4" />
                                <h3 className="text-xl font-bold mb-4">Networking</h3>
                                <p className="text-gray-600">Connect with like-minded peers and business professionals.</p>
                            </div>
                            
                            <div className="bg-tropaz-50 p-8 rounded-xl" data-aos="fade-up" data-aos-delay="300">
                                <FaLightbulb className="w-12 h-12 text-tropaz-700 mb-4" />
                                <h3 className="text-xl font-bold mb-4">Leadership</h3>
                                <p className="text-gray-600">Develop essential leadership and business skills for your future career.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </motion.div>
    );
}
