import { motion } from 'framer-motion';
import { FaTrophy, FaChalkboardTeacher, FaLaptopCode, FaUserFriends } from 'react-icons/fa';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const EventSection = ({ title, events }) => (
    <motion.section
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="mb-12"
    >
        <h2 className="text-3xl md:text-4xl font-bold text-tropaz-700 mb-6 text-center">{title}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-6xl mx-auto">
            {events.map((event, index) => (
                <motion.a
                    key={event.name}
                    href={event.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-4 bg-white rounded-lg shadow-md hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                >
                    <h3 className="text-lg font-semibold text-tropaz-600 hover:text-tropaz-800 transition-colors">
                        {event.name}
                    </h3>
                </motion.a>
            ))}
        </div>
    </motion.section>
);

export default function Members() {
    const objectiveTests = [
        { name: "Accounting I", link: "https://www.fbla-pbl.org/fbla/competitive-events/accounting-i/" },
        { name: "Accounting II", link: "https://www.fbla-pbl.org/fbla/competitive-events/accounting-ii/" },
        { name: "Advertising", link: "https://www.fbla-pbl.org/fbla/competitive-events/advertising/" },
        { name: "Agribusiness", link: "https://www.fbla-pbl.org/fbla/competitive-events/agribusiness/" },
        { name: "Business Calculations", link: "https://www.fbla-pbl.org/fbla/competitive-events/business-calculations/" },
        { name: "Business Communication", link: "https://www.fbla-pbl.org/fbla/competitive-events/business-communication/" },
        { name: "Business Law", link: "https://www.fbla-pbl.org/fbla/competitive-events/business-law/" },
        { name: "Computer Problem Solving", link: "https://www.fbla-pbl.org/fbla/competitive-events/computer-problem-solving/" },
        { name: "Cyber Security", link: "https://www.fbla-pbl.org/fbla/competitive-events/cyber-security/" },
        { name: "Economics", link: "https://www.fbla-pbl.org/fbla/competitive-events/economics/" },
        { name: "Insurance & Risk Management", link: "https://www.fbla-pbl.org/fbla/competitive-events/insurance-risk-management/" },
        { name: "Personal Finance", link: "https://www.fbla-pbl.org/fbla/competitive-events/personal-finance/" }
    ];

    const presentationEvents = [
        { name: "Business Ethics", link: "https://www.fbla-pbl.org/fbla/competitive-events/business-ethics/" },
        { name: "Business Plan", link: "https://www.fbla-pbl.org/fbla/competitive-events/business-plan/" },
        { name: "Client Service", link: "https://www.fbla-pbl.org/fbla/competitive-events/client-service/" },
        { name: "Coding & Programming", link: "https://www.fbla-pbl.org/fbla/competitive-events/coding-programming/" },
        { name: "Computer Game & Simulation Programming", link: "https://www.fbla-pbl.org/fbla/competitive-events/computer-game-simulation/" },
        { name: "Data Analysis", link: "https://www.fbla-pbl.org/fbla/competitive-events/data-analysis/" },
        { name: "Digital Video Production", link: "https://www.fbla-pbl.org/fbla/competitive-events/digital-video-production/" },
        { name: "E-business", link: "https://www.fbla-pbl.org/fbla/competitive-events/e-business/" },
        { name: "Electronic Career Portfolio", link: "https://www.fbla-pbl.org/fbla/competitive-events/electronic-career-portfolio/" },
        { name: "Future Business Leader", link: "https://www.fbla-pbl.org/fbla/competitive-events/future-business-leader/" },
        { name: "Graphic Design", link: "https://www.fbla-pbl.org/fbla/competitive-events/graphic-design/" },
        { name: "Job Interview", link: "https://www.fbla-pbl.org/fbla/competitive-events/job-interview/" }
    ];

    const productionEvents = [
        { name: "Computer Applications", link: "https://www.fbla-pbl.org/fbla/competitive-events/computer-applications/" },
        { name: "Database Design & Applications", link: "https://www.fbla-pbl.org/fbla/competitive-events/database-design-applications/" },
        { name: "Spreadsheet Applications", link: "https://www.fbla-pbl.org/fbla/competitive-events/spreadsheet-applications/" },
        { name: "Word Processing", link: "https://www.fbla-pbl.org/fbla/competitive-events/word-processing/" }
    ];

    const rolePlayEvents = [
        { name: "Banking & Financial Systems", link: "https://www.fbla-pbl.org/fbla/competitive-events/banking-financial-systems/" },
        { name: "Entrepreneurship", link: "https://www.fbla-pbl.org/fbla/competitive-events/entrepreneurship/" },
        { name: "Help Desk", link: "https://www.fbla-pbl.org/fbla/competitive-events/help-desk/" },
        { name: "Hospitality & Event Management", link: "https://www.fbla-pbl.org/fbla/competitive-events/hospitality-event-management/" },
        { name: "International Business", link: "https://www.fbla-pbl.org/fbla/competitive-events/international-business/" },
        { name: "Management Decision Making", link: "https://www.fbla-pbl.org/fbla/competitive-events/management-decision-making/" },
        { name: "Marketing", link: "https://www.fbla-pbl.org/fbla/competitive-events/marketing/" },
        { name: "Network Design", link: "https://www.fbla-pbl.org/fbla/competitive-events/network-design/" },
        { name: "Parliamentary Procedure", link: "https://www.fbla-pbl.org/fbla/competitive-events/parliamentary-procedure/" },
        { name: "Sports & Entertainment Management", link: "https://www.fbla-pbl.org/fbla/competitive-events/sports-entertainment-management/" }
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen bg-gray-50"
        >
            <Navbar />

            <main className="pt-24 pb-16 px-4">
                <div className="max-w-7xl mx-auto">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-center mb-16"
                    >
                        <h1 className="text-4xl md:text-6xl font-bold text-tropaz-900 mb-4">
                            FBLA Competitive Events
                        </h1>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Explore our comprehensive list of FBLA competitive events and find the perfect opportunity to showcase your skills
                        </p>
                    </motion.div>

                    {/* Event Category Descriptions */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            className="bg-white p-6 rounded-xl shadow-md"
                        >
                            <h3 className="text-xl font-bold text-tropaz-700 mb-3 flex items-center">
                                <FaTrophy className="mr-2" /> Objective Tests
                            </h3>
                            <p className="text-gray-600">
                                Multiple-choice exams that assess knowledge in various business topics. These tests cover subjects like accounting, 
                                business law, and economics, testing core concepts within a time limit. Perfect for students who excel in 
                                demonstrating their business knowledge.
                            </p>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, x: 20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            className="bg-white p-6 rounded-xl shadow-md"
                        >
                            <h3 className="text-xl font-bold text-tropaz-700 mb-3 flex items-center">
                                <FaChalkboardTeacher className="mr-2" /> Presentation Events
                            </h3>
                            <p className="text-gray-600">
                                Events that involve preparing and delivering presentations on business topics. Participants create business 
                                plans, digital portfolios, and must effectively communicate their ideas while answering questions from judges. 
                                Great for developing public speaking skills.
                            </p>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            className="bg-white p-6 rounded-xl shadow-md"
                        >
                            <h3 className="text-xl font-bold text-tropaz-700 mb-3 flex items-center">
                                <FaLaptopCode className="mr-2" /> Production Events
                            </h3>
                            <p className="text-gray-600">
                                Hands-on events focusing on creating business documents using software applications. Participants demonstrate 
                                their technical skills by producing professional documents within a time limit using tools like word processors, 
                                spreadsheets, and databases.
                            </p>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, x: 20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            className="bg-white p-6 rounded-xl shadow-md"
                        >
                            <h3 className="text-xl font-bold text-tropaz-700 mb-3 flex items-center">
                                <FaUserFriends className="mr-2" /> Role Play Events
                            </h3>
                            <p className="text-gray-600">
                                Interactive events that simulate real business scenarios. Participants think on their feet to solve problems 
                                and develop strategies while interacting with judges acting as clients or managers. Excellent for developing 
                                quick thinking and professional communication skills.
                            </p>
                        </motion.div>
                    </div>

                    <EventSection title="Objective Tests" events={objectiveTests} />
                    <EventSection title="Presentation Events" events={presentationEvents} />
                    <EventSection title="Production Events" events={productionEvents} />
                    <EventSection title="Role Play Events" events={rolePlayEvents} />

                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="mt-20 bg-tropaz-900 rounded-2xl p-8 text-center text-white"
                    >
                        <h2 className="text-3xl font-bold mb-4">Ready to Compete?</h2>
                        <p className="text-lg mb-8 opacity-90">
                            Join Wharton FBLA today and start your journey in competitive events!
                        </p>
                        <motion.a
                            href="/contact"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="inline-block bg-white text-tropaz-700 px-8 py-3 rounded-lg font-semibold hover:bg-tropaz-50 transition-colors duration-300"
                        >
                            Get Started
                        </motion.a>
                    </motion.div>
                </div>
            </main>

            <Footer />
        </motion.div>
    );
}
